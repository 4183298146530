import { Fragment } from "react";

function DesktopList(props) {
    return (
        <Fragment>
            <li>{props.desc}</li>
        </Fragment>
    )
}


export default DesktopList